<template>
    <div class="staffs">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header modal-header_no-border">
                    <h3 class="block-title">
                        {{ staffs.text }}
                    </h3>
                    <button
                        type="button"
                        class="btn-block-option close-modal"
                        @click="$modal.hide('studied-staffs')"
                    >
                        ×
                    </button>
                </div>
                <div class="block-content content">
                    <div
                        v-for="staff in staffs.data"
                        :key="staff.id"
                        class="staffs__item"
                    >
                        <img
                            class="staffs__item-photo"
                            :src="staff.image_zoom_out || defaultAvatar"
                            alt="фото сотрудника"
                        />
                        <div class="staffs__item-info">
                            <p class="staffs__item-info-title">
                                {{ staff.full_name }}
                            </p>
                            <p class="staffs__item-info-subtitle">
                                {{ staff.position }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import defaultAvatar from 'assets/img/avatars/default_avatar.jpg'
export default {
    name: 'StudiedStaffsModal',
    data () {
        return {
            defaultAvatar
        }
    },
    props: ['staffs']
}
</script>

<style lang="scss" scoped>
@import "#sass/v-style";

.v--modal-overlay .v--modal-box {
    overflow: visible;
}

.modal-dialog {
    max-width: 840px;
    margin: 0;

    @include below($tablet) {
        max-width: none;
    }
}

.modal-content {
    padding: 30px;

    @include below($mobile) {
        padding: 15px;
    }
}

.modal-header {
    padding: 0;
    margin-bottom: 35px;

    @include below($mobile) {
        margin-bottom: 15px;
    }
}

.block-content {
    padding: 0;
    display: flex;
    flex-direction: column;
    & > .staffs__item + .staffs__item {
        margin-top: 23px;
    }
}

.staffs {
    &__item {
        display: flex;
        align-items: center;
        &-photo {
            margin-right: 12px;
            width: 40px;
            min-width: 40px;
            height: 40px;
            min-height: 40px;
            overflow: hidden;
            border-radius: 50%;
        }
        &-info {
            display: flex;
            flex-direction: column;
            & > p {
                margin: 0;
            }
            &-title {
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                color: #000;
            }
            &-subtitle {
                font-size: 14px;
                line-height: 19px;
                color: #a4aebd;
            }
        }
    }
}
</style>
